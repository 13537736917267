import React from 'react'
import './filmoviglavnianimator.scss'

export default function FilmoviGlavniAnimator({ children }) {
    return (
        <div className='filmovi-glavni-animator'>
            <h3 className='filmovi-glavni-animator-title'>Filmovi na kojima je radio kao glavni animator</h3>
            {children}
        </div>
    )
}