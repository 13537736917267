import React from 'react'
import './knjigeilustrirao.scss'

export default function KnjigeIlustrirao({ children }) {
    return (
        <div className='knjige-ilustrirao'>
            <h3 className='knjige-ilustrirao-title'>Knjige koje je ilustrirao</h3>
            {children}
        </div>
    )
}
