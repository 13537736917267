import React from "react"
import "./card.scss"

export default function Card({ title, subtitle, link }) {
  return (
    <div className="card-instance">
      <h2 className="card-title">{title}</h2>
      <h3 className="card-subtitle">{subtitle}</h3>
      <div className="card-mail">
        <a href={link}>POVEZNICA</a>
      </div>
    </div>
  )
}
