import React from 'react'
import { FaCloudDownloadAlt } from 'react-icons/fa';
import './document.scss'

export default function Document({ name, uploadDate }) {
    return (
        <div className='document'>
            <p className='document-name'>{name}</p>
            <div className="document-date-and-icon">
                <p className='document-upload-date'>{uploadDate}</p>
                <FaCloudDownloadAlt className='document-icon' />
            </div>
        </div>
    )
}
