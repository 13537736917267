import React from 'react'
import { FaBook } from 'react-icons/fa';
import './knjizevnadjela.scss'

export default function KnjizevnaDjela({ children }) {
    return (
        <div className='knjizevna-djela'>
            <h3 className='knjizevna-djela-title'>Književna djela<FaBook className='knjizevna-djela-icon' /></h3>
            {children}
        </div>
    )
}
