import React from "react"
import LogoImg from "../img/logo-kreativna-kuca.png"
import { Link } from "gatsby"
import './logo.scss'

export default function Logo() {
  return (
    <div className="logo">
      <Link to="/">
        <img className="logo-img" src={LogoImg} alt="Kreativna Kuća logo" />
      </Link>
    </div>
  )
}
