import React from 'react'
import { FaBuffer } from 'react-icons/fa';
import './nominations.scss'

export default function Nominations({ children }) {
    return (
        <div className='nominations'>
            <h3 className='nominations-title'>Nominacije i priznanja <FaBuffer className='nominations-icon' /></h3>
            <div className="nominations-children">{children}
            </div>
        </div>
    )
}
