import React from 'react'
import Document from './Document';
import './documents.scss'
import { StaticQuery, graphql, Link } from "gatsby"

export default () => (
  <StaticQuery
    query={graphql`
    query {
        documents: allContentfulDocument {
        edges {
            node {
            dateAdded(formatString: "DD/MM/YYYY")
            name
            file {
                file {
                url
                }
            }
            }
        }
        }
    }`}
    render={data => (
        <div className="documents">
            <div className="documents-wrapper">
                {data.documents.edges.map(edge => {
                    return (<a className='documents-link-wrapper' href={edge.node.file.file.url} target="_blank" rel="noopener noreferrer" download>
                        <Document name={edge.node.name} uploadDate={edge.node.dateAdded} />
                    </a>)
                })}
            </div>
        </div>
    )}
  />
)